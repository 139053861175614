<script
    lang="ts"
    setup
>
    import type { Content } from '@tiptap/vue-3'
    import { EditorContent, useEditor } from '@tiptap/vue-3'
    import { Document } from '@tiptap/extension-document'
    import { History } from '@tiptap/extension-history'
    import { Text } from '@tiptap/extension-text'
    import { Paragraph } from '@tiptap/extension-paragraph'
    import { Bold } from '@tiptap/extension-bold'
    import { Italic } from '@tiptap/extension-italic'
    import { Strike } from '@tiptap/extension-strike'
    import { Underline } from '@tiptap/extension-underline'
    import { Heading } from '@tiptap/extension-heading'
    import { Placeholder } from '@tiptap/extension-placeholder'
    import { HardBreak } from '@tiptap/extension-hard-break'
    import { Dropcursor } from '@tiptap/extension-dropcursor'
    import { Gapcursor } from '@tiptap/extension-gapcursor'
    import { Link } from '@tiptap/extension-link'
    import { Image } from '@tiptap/extension-image'
    import { ListItem } from '@tiptap/extension-list-item'
    import { OrderedList } from '@tiptap/extension-ordered-list'
    import { BulletList } from '@tiptap/extension-bullet-list'
    import { Blockquote } from '@tiptap/extension-blockquote'
    import { CodeBlock } from '@tiptap/extension-code-block'
    import { Table } from '@tiptap/extension-table'
    import { TableCell } from '@tiptap/extension-table-cell'
    import { TableHeader } from '@tiptap/extension-table-header'
    import { TableRow } from '@tiptap/extension-table-row'

    type Props = {
        title: string
        content: string
        editable?: boolean
        titlePlaceholder?: string
        contentPlaceholder?: string
    }

    type Emit = {
        (event: 'update:title', value: any): void
        (event: 'update:content', value: any): void
    }

    const props = withDefaults(defineProps<Props>(), {
        titlePlaceholder: '',
        contentPlaceholder: '',
        editable: false,
    })

    const emit = defineEmits<Emit>()

    const titleRef = ref<HTMLTextAreaElement>()

    const showMenu = ref<boolean>(false)
    const titleMinHeight = ref<string>('auto')
    const titleOverflow = ref<string>('hidden')

    const titleStyle = computed(() => ({
        minHeight: titleMinHeight.value,
        overflow: titleOverflow.value,
    }))

    const style = useCssModule()

    const editorClass = computed<string[]>(() => {
        const classes = [ style['editor'] ]

        if (!props.editable) {
            classes.push(style['editor--readonly'])
        }

        return classes
    })

    const titleModel = computed<string>({
        get() {
            return props.title
        },
        set(value) {
            emit('update:title', value)
        },
    })

    const focus = (forcedFocusContent = false): void => {
        setTimeout(() => {
            if (forcedFocusContent) {
                editor.value.commands.focus('end', { scrollIntoView: false })

                return
            }

            if (!props.title) {
                titleRef.value.focus()
            } else {
                editor.value.commands.focus('end', { scrollIntoView: false })
            }
        }, 220)
    }

    const editor = useEditor({
        content: props.content,
        editable: props.editable,
        onUpdate: () => {
            emit('update:content', editor.value.isEmpty ? '' : editor.value.getHTML())
        },
        onCreate() {
            focus()
        },
        extensions: [
            Document,
            History,
            Text,
            Paragraph,
            Bold,
            Italic,
            Strike,
            Underline,
            Heading.configure({ levels: [ 1, 2 ] }),
            Placeholder.configure({
                placeholder: () => {
                    return props.contentPlaceholder
                },
            }),
            HardBreak,
            Dropcursor,
            Gapcursor,
            Link.configure({
                openOnClick: !props.editable,
            }),
            Image.configure({
                allowBase64: true,
            }),
            ListItem,
            OrderedList,
            BulletList,
            Blockquote,
            CodeBlock,
            Table.configure({
                resizable: !props.editable,
                allowTableNodeSelection: !props.editable,
            }),
            TableCell,
            TableHeader,
            TableRow,
        ],
    })

    const loadImage = async (imageFile: File): Promise<void> => {
        editor.value
            .chain()
            .focus()
            .setImage({ src: await fileUtil.getBase64(imageFile) })
            .run()
    }

    const setContent = (value: Content): void => {
        showMenu.value = false

        editor.value.commands.setContent(value)

        nextTick(() => focus())
    }

    const setEditable = (value: boolean): void => {
        showMenu.value = false

        editor.value.setEditable(value)

        if (value) {
            nextTick(() => focus())
        }
    }

    const resizeTitle = (): void => {
        if (!titleRef.value) {
            return
        }

        titleMinHeight.value = 'auto'

        nextTick(() => {
            if (titleRef.value.scrollHeight >= 300) {
                titleMinHeight.value = '300px'
                titleOverflow.value = 'auto'

                return
            }

            titleOverflow.value = 'hidden'

            titleMinHeight.value = titleRef.value.scrollHeight + 'px'
        })
    }

    const getTitleInput = (): HTMLTextAreaElement | null => titleRef.value

    defineExpose({
        setContent,
        setEditable,
        resizeTitle,
        getTitleInput,
    })

    watch(titleModel, () => resizeTitle())

    onMounted(() => resizeTitle())
</script>

<template>
    <AppImageDropZone
        style="border-radius: 4px 4px 0 0"
        :disabled="!props.editable"
        @dropped="loadImage"
    >
        <template #text>
            {{ $t('drag-image-here-to-add-it') }}
        </template>

        <div
            ref="editorContent"
            :class="editorClass"
            @click="showMenu = true"
        >
            <textarea
                ref="titleRef"
                v-model="titleModel"
                :class="$style['editor__title-input']"
                :placeholder="props.titlePlaceholder"
                :readonly="!props.editable"
                :style="titleStyle"
                @input="resizeTitle()"
                @keydown.enter.prevent="focus(true)"
            ></textarea>

            <EditorContent
                key="content"
                :editor="editor"
                :class="$style['editor__content']"
            />

            <ClientOnly>
                <AppTextEditorMenus
                    v-if="props.editable && editor"
                    key="menus"
                    :show="showMenu"
                    :editor="editor"
                />
            </ClientOnly>
        </div>
    </AppImageDropZone>
</template>

<style
    lang="sass"
    module
>
    @use 'assets/styles/sass/placeholders'

    .editor
        overflow: auto
        display: flex
        flex-direction: column
        width: 100%
        height: 100%
        border: 1px solid #aaadb8
        border-bottom: none
        border-radius: 4px 4px 0 0
        background: #fff
        transition: border-color var(--transition-default-duration-with-ease)

        &--readonly
            border: none

        &:focus-within
            border-color: #000

        &__content
            flex: 1
            width: 100%

        &__title-input
            @extend %hide-scroll

            appearance: none
            outline: none
            border: none
            resize: none
            width: 100%
            height: 18px
            padding: 16px 16px 0 16px
            font-style: normal
            font-size: 24px
            font-weight: 500
            line-height: 29px
            background: none
            color: #000
            caret-color: auto

            --placeholder-color: #8a8f9e

            &::-webkit-input-placeholder
                color: var(--placeholder-color)

            &::-moz-placeholder
                color: var(--placeholder-color)

            &:-ms-input-placeholder
                color: var(--placeholder-color)

            &:-moz-placeholder
                color: var(--placeholder-color)

        p
            min-height: 20px
            line-height: 20px
            font-size: 16px
            font-weight: 400

        h1
            min-height: 29px
            line-height: 29px
            font-size: 24px
            font-weight: 500

        h2
            min-height: 19px
            line-height: 19px
            font-size: 16px
            font-weight: 500

        img
            max-width: 100%
            height: auto
</style>

<style lang="sass">
    .ProseMirror
        outline: none
        height: 100%
        padding: 16px

        > * + *
            margin: 0

        ul,
        ol
            padding: 0 24px

        ul
            list-style-type: disc

            ul
                list-style-type: circle

        ol
            list-style-type: decimal

        blockquote
            padding-left: 1rem
            border-left: 3px solid rgba(#0D0D0D, 0.1)

        pre
            padding: 0.75rem 1rem
            font-family: 'JetBrainsMono', monospace
            background: #0d0d0d
            color: #fff
            border-radius: 0.5rem

        code
            padding: 0
            font-size: 0.8rem
            background: none
            color: inherit

        a
            text-decoration: underline
            outline: none
            color: #000
            transition: color var(--transition-default-duration-with-ease)
            cursor: pointer

            &:hover,
            &:active
                color: #8a8f9e

        table
            overflow: hidden
            border-collapse: collapse
            table-layout: fixed
            width: 100%
            margin: 0

            td,
            th
                box-sizing: border-box
                position: relative
                min-width: 1em
                vertical-align: top
                padding: 3px 5px
                border: 2px solid #ced4da

                > *
                    margin-bottom: 0

            th
                font-weight: 700
                text-align: left
                background-color: #f1f3f5

            .selectedCell:after
                content: ''
                z-index: 2
                pointer-events: none
                position: absolute
                top: 0
                left: 0
                right: 0
                bottom: 0
                background: rgba(249, 222, 86, 0.4)

            .column-resize-handle
                pointer-events: none
                position: absolute
                top: 0
                right: -2px
                bottom: -2px
                width: 4px
                background-color: #f9de56ff

            p
                margin: 0

        .tableWrapper
            padding: 1rem 0
            overflow-x: auto

        .resize-cursor
            cursor: ew-resize
            cursor: col-resize

    .ProseMirror .is-empty::before
        content: attr(data-placeholder)
        pointer-events: none
        float: left
        height: 0
        color: #aaadb8
        font-size: 16px
        font-weight: 400

    img.ProseMirror-selectednode
        outline: 3px solid #efb31a
</style>
