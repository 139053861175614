<script
    lang="ts"
    setup
>
    import type { AnyFn } from '~/ts/types/common'
    import type { FormSubmitContext } from '~/ts/types/form'
    import type { KBArticle, KBCategory, KBTreeItem } from '~/ts/types/knowledge-base'
    import { KBItemStatusEnum } from '~/ts/enums/knowledge-base'
    import { useKnowledgeBaseStore } from '~/stores/knowledge-base'
    import { useUserStore } from '~/stores/user'
    import { useChatStore } from '~/stores/chat'
    import handleFormError from '~/helpers/handleFormError'
    import rules from '~/helpers/formValidationRules'

    type FormRules = {
        status: AnyFn
        author: AnyFn
    }

    type FormValues = {
        status: number
        author: number
    }

    const formRef = ref<ReturnType<typeof defineComponent>>()

    const knowledgeBaseStore = useKnowledgeBaseStore()
    const userStore = useUserStore()
    const chatStore = useChatStore()
    const { t } = useLang()

    const formRules = shallowRef<FormRules>({
        status: value => rules.ruleChain(
            () => rules.required(value),
            () => rules.oneOf(value, knowledgeBaseStore.statusOptions.map(v => v.value)),
        ),
        author: value => rules.ruleChain(
            () => rules.required(value),
            () => rules.oneOf(value, knowledgeBaseStore.authorOptions.map(v => v.value)),
        ),
    })

    const formValues = ref<FormValues>({
        status: knowledgeBaseStore.currentArticle.status,
        author: knowledgeBaseStore.currentArticle.operator_id,
    })

    const pendingFor = ref<'submit' | 'delete' | undefined>()

    const selectedAuthor = computed(() => {
        return knowledgeBaseStore.authors.find(v => v.id === formValues.value.author)
    })

    const isArticleDeletingAvailable = computed<boolean>(() => {
        if (knowledgeBaseStore.allArticles.length > 1) {
            return true
        }

        return !knowledgeBaseStore.currentArticle?.new
    })

    watch(() => knowledgeBaseStore.currentArticle, (value) => {
        formValues.value.status = value?.status
        formValues.value.author = value?.operator_id
    })

    const pushSubmitSuccessNotify = () => {
        const notificationText = knowledgeBaseStore.currentArticle.new
            ? 'successfully-created'
            : 'successfully-updated'

        useNotify().push({
            type: 'success',
            tag: 'kb',
            text: t(notificationText),
        })
    }

    const submit = async ({ status, author }: FormValues, { setFieldError }: FormSubmitContext): Promise<void> => {
        if (pendingFor.value) {
            return
        }

        pendingFor.value = 'submit'

        let data: KBArticle
        let error: Error

        if (!knowledgeBaseStore.currentArticle.title) {
            useNotify().push({
                type: 'error',
                tag: 'kb-error',
                text: t('title-is-required'),
            })

            pendingFor.value = undefined

            return
        }

        if (knowledgeBaseStore.currentArticle.new) {
            try {
                data = await useApi().knowledgeBase.createArticle({
                    contentLanguage: knowledgeBaseStore.contentLanguage,
                    data: {
                        node_id: knowledgeBaseStore.currentArticle.parent_id,
                        title: knowledgeBaseStore.currentArticle.title,
                        content: knowledgeBaseStore.currentArticle.content,
                        status,
                        operator_id: author,
                    },
                })
            } catch (_error) {
                error = _error
            }
        } else {
            try {
                data = await useApi().knowledgeBase.updateArticle({
                    id: knowledgeBaseStore.currentArticle._id,
                    contentLanguage: knowledgeBaseStore.contentLanguage,
                    data: {
                        title: knowledgeBaseStore.currentArticle.title,
                        content: knowledgeBaseStore.currentArticle.content,
                        status,
                        operator_id: author,
                    },
                })
            } catch (_error) {
                error = _error
            }
        }

        if (error) {
            pendingFor.value = undefined

            return handleFormError({ error, setFieldError })
        }

        if (knowledgeBaseStore.currentArticle.new) {
            const parentItem = knowledgeBaseStore.findItemById(
                knowledgeBaseStore.currentArticle.parentItem._id,
            ) as KBCategory | KBTreeItem

            const newArticleIndex = parentItem.children.findIndex(v => (v as KBArticle).new)

            parentItem.children[newArticleIndex] = data

            userStore.updateOnboarding('is_create_kb')
        } else {
            const item = knowledgeBaseStore.findItemById(knowledgeBaseStore.currentArticle._id)

            Object.assign(item, data)
        }

        knowledgeBaseStore.setArticle(data)

        pushSubmitSuccessNotify()

        pendingFor.value = undefined

        chatStore.fillKnowledgeBase(true)
    }

    const deleteArticle = async (): Promise<void> => {
        if (pendingFor.value || !isArticleDeletingAvailable.value) {
            return
        }

        if (knowledgeBaseStore.currentArticle.new) {
            if (!knowledgeBaseStore.currentArticle.isEmpty() && !await useConfirm()) {
                return
            }

            pendingFor.value = 'delete'

            const parentItem = knowledgeBaseStore.findItemById(
                knowledgeBaseStore.currentArticle.parentItem._id,
            ) as KBCategory | KBTreeItem
            const currentArticleCopy = cloneValue(knowledgeBaseStore.currentArticle)
            const parentItemCopy = cloneValue(parentItem)
            const articleIndex = parentItem.children.findIndex(v => (v as KBArticle).new)

            parentItem.children.splice(articleIndex, 1)

            await knowledgeBaseStore.setNextArticle(currentArticleCopy as KBArticle, parentItemCopy)

            pendingFor.value = undefined

            return
        } else if (!await useConfirm({
            titleText: t('article-deleting'),
            bodyText: t('article-deleting-hint', knowledgeBaseStore.currentArticle.title),
            confirmText: t('remove'),
        })) {
            return
        }

        pendingFor.value = 'delete'

        try {
            await useApi().knowledgeBase.deleteArticle({
                id: knowledgeBaseStore.currentArticle._id,
            })
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
        } catch (error) {
            pendingFor.value = undefined

            return
        }

        const parentItem = knowledgeBaseStore.findItemParent(knowledgeBaseStore.currentArticle as KBArticle)
        const currentArticleCopy = cloneValue(knowledgeBaseStore.currentArticle)
        const parentItemCopy = cloneValue(parentItem)
        const articleIndex = parentItem.children.findIndex(v => v._id === currentArticleCopy._id)

        parentItem.children.splice(articleIndex, 1)

        await knowledgeBaseStore.setNextArticle(currentArticleCopy as KBArticle, parentItemCopy)

        useNotify().push({
            type: 'success',
            tag: 'kb',
            text: t('successfully-deleted'),
        })

        pendingFor.value = undefined

        chatStore.fillKnowledgeBase(true)
    }

    const bus = useEventBus()

    const busCleaners = [
        bus.on(BUS_EVENT_KB_SAVE_ARTICLE, () => formRef.value.triggerSubmit({ ifDirty: true })),
        bus.on(BUS_EVENT_KB_FORM_SET_DIRTY, () => formRef.value.setDirty()),
    ]

    onBeforeUnmount(() => busCleaners.forEach(cb => cb()))
</script>

<template>
    <AppForm
        ref="formRef"
        v-slot="{ errors, setDirty }"
        submit-if-dirty
        :values="formValues"
        :rules="formRules"
        class="gap-6"
        @submit="submit"
        @submit-prevented:not-dirty="pushSubmitSuccessNotify()"
    >
        <div class="flex items-center gap-4">
            <AppButton
                v-if="isArticleDeletingAvailable"
                key="remove"
                secondary
                :disabled="pendingFor === 'submit'"
                :loading="pendingFor === 'delete'"
                @click="deleteArticle()"
            >
                {{ $t('remove') }}
            </AppButton>

            <AppButton
                key="submit"
                type="submit"
                :disabled="pendingFor === 'delete'"
                :loading="pendingFor === 'submit'"
                class="flex-1"
            >
                {{ knowledgeBaseStore.currentArticle.new ? $t('create') : $t('save') }}
            </AppButton>
        </div>

        <div class="flex flex-col gap-4">
            <AppFormFieldSelect
                v-model="formValues.status"
                v-model:error="errors.status"
                name="status"
                :label="$t('status')"
                :options="knowledgeBaseStore.statusOptions"
                :dropdown-args="{ offsetY: 10, width: '100%' }"
                container-class="!w-full !mb-0"
                @update:model-value="setDirty()"
            >
                <template #activator-input="{ inputValue, inputClass, activatorProps }">
                    <div
                        v-if="formValues.status || formValues.status === KBItemStatusEnum.Unpublished"
                        :class="[ inputClass, 'flex items-center gap-1.5 !h-[30px]' ]"
                    >
                        <AppIconStatusVariantTrue
                            v-if="formValues.status === KBItemStatusEnum.Published"
                            key="published"
                        />

                        <AppIconStatusVariantFalse
                            v-else
                            key="unpublished"
                        />

                        <input
                            key="input"
                            :value="inputValue"
                            :class="inputClass"
                            readonly
                            class="pointer-events-none"
                            autocomplete="off"
                            @focusin="activatorProps.toggle"
                        />
                    </div>
                </template>

                <template #option-text="{ option }">
                    <div class="flex items-center gap-1">
                        <AppIconStatusVariantTrue
                            v-if="option.value === KBItemStatusEnum.Published"
                            key="published"
                        />

                        <AppIconStatusVariantFalse
                            v-else
                            key="unpublished"
                        />

                        <span>
                            {{ option.text }}
                        </span>
                    </div>
                </template>
            </AppFormFieldSelect>

            <AppFormFieldSelect
                v-model="formValues.author"
                v-model:error="errors.author"
                name="author"
                :label="$t('author')"
                :options="knowledgeBaseStore.authorOptions"
                searchable
                search-placeholder-trans-key="search-by-operator"
                search-by-extra-value="searchValue"
                :dropdown-args="{ offsetY: 10, width: '100%' }"
                container-class="!w-full !mb-0"
                @update:model-value="setDirty()"
            >
                <template #activator-input="{ inputValue, inputClass, activatorProps }">
                    <div
                        v-if="selectedAuthor"
                        :class="[ inputClass, 'flex items-center gap-2.5 !h-[36px]' ]"
                    >
                        <AppAvatar
                            :src="selectedAuthor.user?.photo?.thumbnails?.v60x60"
                            size="30"
                        >
                            {{ selectedAuthor.user?.name?.[0] || selectedAuthor.user?.email[0] }}
                        </AppAvatar>

                        <div class="pointer-events-none">
                            {{ selectedAuthor.user?.name }}

                            <input
                                key="input"
                                :value="inputValue"
                                :class="inputClass"
                                readonly
                                class="!text-[#8a8f9e]"
                                autocomplete="off"
                                @focusin="activatorProps.toggle"
                            />
                        </div>
                    </div>
                </template>

                <template #option-text="{ option }">
                    <AppTooltip
                        :disabled="option._extra.enabled"
                        offset-x="-85"
                    >
                        <template #activator="{ open, close }">
                            <div
                                class="flex items-center gap-2.5"
                                @mouseenter.passive="open"
                                @mouseleave.passive="close"
                            >
                                <AppAvatar
                                    :src="option._extra.photo?.thumbnails?.v60x60"
                                    size="30"
                                    :class="{ 'opacity-30': !option._extra.enabled }"
                                >
                                    {{ option._extra.name?.[0] || option._extra.email[0] }}
                                </AppAvatar>

                                <div class="flex flex-col gap-0.5">
                                    {{ option._extra.name }}

                                    <div class="text-[#8a8f9e]">
                                        {{ option._extra.email }}
                                    </div>
                                </div>
                            </div>
                        </template>

                        {{ $t('disabled-operator') }}
                    </AppTooltip>
                </template>
            </AppFormFieldSelect>
        </div>
    </AppForm>
</template>
